import React, { useState } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

import logo from '../images/fig.png';

const Navigation = () => {
  const [burger, setBurger] = useState(false);

  const toggleBurger = () => setBurger(!burger);

  return (
    <nav className="navbar is-spaced" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img src={logo} alt="Grafig Ltd logo" />
          </Link>

          <button
            className={classnames('navbar-burger burger button is-white', { 'is-active': burger })}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarMenu"
            onClick={toggleBurger}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </div>

        <div id="navbarMenu" className={classnames('navbar-menu', { 'is-active': burger })}>
          <div className="navbar-end">
            <Link className="navbar-item" to="/services/">
              Services
            </Link>
            <Link className="navbar-item" to="/about-us/">
              About us
            </Link>
            <Link className="navbar-item" to="/blog/">
              Blog
            </Link>
            <div className="navbar-item">
              <Link className="button is-link is-light is-outlined" to="/contact/">
                <strong>Contact</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
