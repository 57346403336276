import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <p>
        Built in London / <Link to="/privacy-policy/">Privacy Policy</Link> /{' '}
        <Link to="/terms-of-use/">Terms of Use</Link> / © {new Date().getFullYear()} all rights reserved.
      </p>
    </div>
  </footer>
);

export default Footer;
