import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import Cookies from 'js-cookie';

const getConsentCookie = () => {
  return Cookies.get('consent') || window.localStorage.getItem('consent') || 'false';
};

const setConsentCookie = () => {
  Cookies.set('consent', 'true', { expires: 3650 });
  window.localStorage.setItem('consent', 'true');
};

const CookieConsent = () => {
  const [consent, setConsent] = useState(true);

  useEffect(() => {
    let newConsent = false;
    const consentCookie = getConsentCookie();
    if (consentCookie === 'true') {
      setConsentCookie();
      newConsent = true;
    } else if (consentCookie === 'false') {
      newConsent = false;
    }
    setConsent(newConsent);
  }, []);

  const onClick = () => {
    setConsentCookie();
    setConsent(true);
  };

  if (consent) {
    return null;
  }

  return (
    <div
      style={{
        background: '#bbbbbb',
        padding: '20px 0',
      }}
    >
      <div className="container">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <p>
                Our website uses cookies. For more information, read our{' '}
                <Link to="/privacy-policy/">Privacy Policy</Link>.
              </p>
            </div>
            <div className="level-item">
              <button className="button is-warning" onClick={onClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
